import * as React from 'react';

import {HomePageWidgetStyles} from './HomePageWidget.styles';
import {WidgetCMSLists} from '../lists/lists/lists.class';

interface CMSListWidgetProps {
    getListsErr: string;
    widgetCMSLists: WidgetCMSLists;
}

export const CMSListWidget = ({getListsErr, widgetCMSLists}: CMSListWidgetProps) => {
    if (!(widgetCMSLists && widgetCMSLists.layout_type === 'List View' && widgetCMSLists.lists.length > 0)) {
        return <></>;
    }
    return (
        <HomePageWidgetStyles>
            <div
                className="card tw-h-full !tw-hidden md:!tw-block"
                id="cms-lists-widget-container"
                data-e2e="cmsListWidget"
            >
                <div className="card-header !tw-bg-transparent tw-flex tw-justify-between tw-items-center !tw-px-2">
                    <h4 className="!tw-m-0">My Lists</h4>
                    <div>
                        <a href="/lists">View All</a>
                    </div>
                </div>
                <div
                    className="card-list content-scroll tw-hidden md:tw-block"
                    data-e2e="cardList"
                >
                    {getListsErr && <div className="abs-center tw-text-red-500">{getListsErr}</div>}
                    {widgetCMSLists.lists.length === 0 && <div className="abs-center tw-text-red-500">You have no recent orders</div>}
                    {!(getListsErr || widgetCMSLists.lists.length === 0) && (
                        <ul>
                            {widgetCMSLists.lists.map((cmsList, index) => (
                                <li
                                    className="tw-flex"
                                    key={index}
                                >
                                    <div className="tw-w-3/12 tw-flex tw-justify-center">
                                        <img
                                            alt="List Sample Image"
                                            height="40"
                                            width="40"
                                            src={`/ProductImageThumbs67${cmsList.image_url || '/noimage.png'}`}
                                        />
                                    </div>
                                    <div className="tw-w-9/12">
                                        <a
                                            href={cmsList.full_url || `/lists/${cmsList.list_number}`}
                                            className="tw-pt-2"
                                        >
                                            {cmsList.list_name}
                                        </a>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </HomePageWidgetStyles>
    );
};
