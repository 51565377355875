import {useEffect, useState} from 'react';

import {OrderableItem} from '../../items/item.class';
import {RecoParams, RecoSchemeName} from '../../reco/reco.class';
import {RecoService} from '../../../client/events/reco/reco.service';

const useRecommendations = (recoService: RecoService, recoParams: RecoParams) => {
    const [error, setError] = useState(null);
    const [items, setItems] = useState<OrderableItem[]>([]);
    const [loaded, setLoaded] = useState(false);
    const [schemeExplanation, setSchemeExplanation] = useState(``);
    const [schemeName, setSchemeName] = useState<RecoSchemeName>(``);

    useEffect(() => {
        if (!recoParams) {
            return;
        }
        recoService
            .getRecoItems(recoParams)
            .then((getRecoItemsRes) => {
                if (getRecoItemsRes && getRecoItemsRes.schemes) {
                    getRecoItemsRes.schemes.forEach((scheme) => {
                        setItems((i) => [...i, ...scheme.items]);
                        setSchemeExplanation(scheme.explanation);
                        setSchemeName(scheme.scheme);
                    });
                    setLoaded(true);
                } else {
                    setLoaded(true);
                }
            })
            .catch(() => {
                setError(`Failed to load recommendations`);
            });
    }, []);

    return {
        items,
        loaded,
        error,
        schemeExplanation,
        schemeName,
    };
};

export default useRecommendations;
