import * as React from 'react';

import PromotionTracker from '../analytics/promotion-tracker/PromotionTracker';
import {faFilePdf} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HomePageContent} from './HomePage';
import {HomeZone3} from '../banner-ads/banner-ads.class';
import {HomePageWidgetStyles} from './HomePageWidget.styles';
import {TemplateHelpers} from '../tools/template-helpers.class';

export interface HomeZoneThreeProps {
    homeZone3: HomeZone3;
    homePageContent: HomePageContent;
}

export const HomeZoneThree = ({homeZone3, homePageContent}: HomeZoneThreeProps) => {
    if (homeZone3?.documents) {
        return (
            <HomePageWidgetStyles>
                <div
                    className="card tw-h-full"
                    id="cmsDocs"
                >
                    <div className="card-header !tw-bg-transparent tw-flex tw-justify-between tw-items-center !tw-px-2">
                        <h4 className="!tw-m-0">{homeZone3.custom_widget_title}</h4>
                    </div>
                    <div className="card-list content-scroll">
                        <ul>
                            {homeZone3.documents.map((cmsDocument, index) => (
                                <li
                                    className="tw-flex"
                                    key={index}
                                >
                                    {cmsDocument.thumbnail && cmsDocument.thumbnail.url && (
                                        <div className="tw-w-3/12 tw-flex tw-justify-center">
                                            <img
                                                alt="List Sample Image"
                                                height="40"
                                                width="40"
                                                src={cmsDocument.thumbnail.url}
                                            />
                                        </div>
                                    )}
                                    {cmsDocument.file_icon && (
                                        <div className="tw-w-3/12 tw-flex tw-justify-center">
                                            <FontAwesomeIcon
                                                icon={faFilePdf}
                                                size="3x"
                                                suppressHydrationWarning
                                            />
                                        </div>
                                    )}
                                    <div>
                                        {cmsDocument.url ? (
                                            <a href={cmsDocument.url}>{cmsDocument.document_name}</a>
                                        ) : cmsDocument.document_asset?.url ? (
                                            <a
                                                href={cmsDocument.document_asset.url}
                                                target="_blank"
                                            >
                                                {cmsDocument.document_name}
                                            </a>
                                        ) : (
                                            cmsDocument.document_name
                                        )}

                                        <div className="caption">
                                            {cmsDocument.last_updated && (
                                                <>Last Updated: {TemplateHelpers.formatDate(cmsDocument.last_updated)}</>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </HomePageWidgetStyles>
        );
    }
    if (homeZone3?.image) {
        return (
            <div
                className="tw-flex tw-items-center tw-justify-center tw-border-gray-150 tw-p-3 tw-h-full"
                style={homeZone3.background_color ? {backgroundColor: homeZone3.background_color} : {}}
            >
                <PromotionTracker
                    promotion={{
                        creative_name: `Zone3 - ${homeZone3.image.name}`,
                        creative_slot: 1,
                        promotion_id: homeZone3.specialNumber.toString(),
                        promotion_name: homeZone3.specialName,
                    }}
                >
                    <a href={homeZone3.link ? homeZone3.link : '/special/' + homeZone3.specialNumber}>
                        <img
                            alt={homeZone3.image_alt_text}
                            height="297"
                            width="191"
                            src={homeZone3.image.url}
                            className="!tw-border-0"
                        />
                    </a>
                </PromotionTracker>
            </div>
        );
    }
    if (homePageContent?.zone_3_default_banner) {
        return (
            <div
                className="tw-hidden md:tw-flex tw-items-center tw-justify-center tw-border-gray-150 tw-p-3 tw-h-full"
                id="orderHistoryAd"
                style={
                    homePageContent.zone_3_default_banner.primary.banner_background_color
                        ? {backgroundColor: homePageContent.zone_3_default_banner.primary.banner_background_color}
                        : {}
                }
            >
                <PromotionTracker
                    promotion={{
                        creative_name: homePageContent.zone_3_default_banner.primary.promotion_creative,
                        creative_slot: 1,
                        promotion_id: homePageContent.zone_3_default_banner.primary.promotion_id,
                        promotion_name: homePageContent.zone_3_default_banner.primary.promotion_name,
                    }}
                >
                    <a href={homePageContent.zone_3_default_banner.primary.banner_link}>
                        <img
                            alt={homePageContent.zone_3_default_banner.primary.alt}
                            height="297"
                            width="191"
                            src={homePageContent.zone_3_default_banner.primary.banner_image.url}
                            className="!tw-border-0"
                        />
                    </a>
                </PromotionTracker>
            </div>
        );
    }
    return <div className="spinner"></div>;
};
