import * as React from 'react';

import {BannerAd} from '../ads/campaign-ads/campaign-ads.class';
import {HomeZone1} from '../banner-ads/banner-ads.class';
import {ResponsiveImagePromo} from './ResponsiveImagePromo';

export interface HomeZoneOneProps {
    bannerAd: BannerAd;
    homeZone1: HomeZone1;
}

export const HomeZoneOne = ({bannerAd, homeZone1}: HomeZoneOneProps) => {
    if (bannerAd) {
        return (
            <div className="tw-mb-6 tw-mt-4 md:tw-mt-0">
                <ResponsiveImagePromo
                    href={bannerAd.banner_destination_url}
                    image={bannerAd.responsive_banner_image}
                    promotion={{
                        creative_name: 'Banner',
                        creative_slot: 1,
                        promotion_id: bannerAd.campaignId,
                        promotion_name: bannerAd.campaignName,
                    }}
                />
            </div>
        );
    }
    if (homeZone1) {
        return (
            <div className="tw-mb-6 tw-mt-4 md:tw-mt-0">
                <ResponsiveImagePromo
                    href={homeZone1.link_url ? homeZone1.link_url : `/special/${homeZone1.specialNumber}`}
                    image={homeZone1.responsive_banner_image}
                    promotion={{
                        creative_name: 'Banner',
                        creative_slot: 1,
                        promotion_id: homeZone1.specialNumber,
                        promotion_name: homeZone1.specialName,
                    }}
                />
            </div>
        );
    }
    return <></>;
};
