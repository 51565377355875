export const colors = {
    gray: {
        100: '#f5f5f5',
        150: '#e5e5e5',
        200: '#d6d6d6',
        250: '#c7c7c7',
        300: '#b7b7b7',
        350: '#a8a8a8',
        400: '#767676',
        450: '#707070',
        500: '#606060',
        550: '#515151',
        600: '#424242',
        650: '#333333',
        700: '#232323',
        900: '#000000',
    },
    red: {
        50: '#fdebed',
        400: '#ea132b',
        500: '#c8102e',
        600: '#b20f21',
        800: '#790a16',
    },
    green: {
        100: '#5daf5d',
        200: '#008000',
    },
    yellow: {
        100: '#ffeebf',
        200: '#997942',
        300: '#ffff00',
    },
    blue: {
        100: '#2d91b7',
        150: '#2196f3',
        200: '#00599c',
    },
    orange: {
        100: '#ff9933',
    },
    white: {
        100: '#ffffff',
    },
    black: {
        100: '#000000',
    },
};

const breakpoints: {[index: string]: number} = {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 1025,
    xl: 1280,
    xxl: 1432,
};

export const mediaQuery = Object.keys(breakpoints)
    .map((key) => [key, breakpoints[key]] as [string, number])
    .reduce((prev, [key, breakpoint]) => {
        prev[key] = `@media (min-width: ${breakpoint}px)`;
        return prev;
    }, {} as {[index: string]: string});
