import * as React from 'react';

import useRecommendations from '../ui/item-spinner/use-recommendations';
import {ItemSpinner} from '../ui/item-spinner/ItemSpinner';
import {RecoService} from '../../client/events/reco/reco.service';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';

interface HomeRecoSpinnerProps {
    user: User;
}

export const HomeRecoSpinner = ({user}: HomeRecoSpinnerProps) => {
    const recoService: RecoService = useService(`recoService`);
    const recommendations = useRecommendations(recoService, {
        scheme: `homeuser1_rr`,
    });

    /**
     * Template
     */
    return (
        <div className="tw-p-4 md:tw-p-0 tw-mt-4 md:tw-mt-0 lg:tw-mb-4 tw-bg-white">
            {recommendations.items?.length > 0 && (
                <div id="reactSpinner">
                    <ItemSpinner
                        gaList={`scheme_${recommendations.schemeName}`}
                        label={recommendations.schemeExplanation}
                        loaded={recommendations.loaded}
                        maxSlides={7}
                        orderableItems={recommendations.items}
                        useAddToOrderModal={true}
                        user={user}
                    />
                </div>
            )}
        </div>
    );
};
